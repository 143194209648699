import React from 'react';

export default function Blog() {
  return (
    <>
    <div className="header2">Blogs</div>
      <div className="paragraph">
        This is where I am really trying. 
      </div>
    </>
  );
}
