import React from 'react';

import profilePicture from '../../profile_pic.jpeg';
import './AboutMe.css';

export default function AboutMe() {
  return (
    <div className="profile">
    <div className="header2">About me</div>
      <div className="section">
        <div>
            <img src={profilePicture} className="picture" alt="Github"/>
        </div>
        <div className="paragraph">
          Name: mutantbutton2
        </div>
        <div className="paragraph">
          Email: mutantbutton2@gmail.com
        </div>
      </div>

      <div className="section">
        <div className="content">I am a software engineer based in London. This is where I <i>try</i> to blog.</div>
        <div className="contnet">I am also ramping up my frontend skills but you have probably noticed that already...</div>
      </div>
    </div>
  );
}
