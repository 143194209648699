import React, { useState } from 'react';
import './Bookmark.css';
import Section from './section/Section';
import Article from './link/Article';

export default function Bookmark() {
  return (
    <>
      <div className="title">Bookmarks</div>
      <div>
        <Section name="cheatsheet" articles={[
          <Article name="k8s" link="/bookmarks/k8s"/>,
          <Article name="postgres" link="/bookmarks/postgres"/>,
        ]} />
        <Section name="frontend" articles={[
        ]} />
        <Section name="docker" articles={[
        ]} />
        {/* <Section name="#chef" articles={['a','b','c']} /> */}
      </div>
    </>
  );
}
