import React, { Component } from "react";
import { Container, Col, Row } from 'react-bootstrap';
import './Layout.css';

import TopBar from '../topbar/TopBar';
import Main from "../main/Main";

function Layout() {
  return (
    <div className="layout">
      <TopBar name="mutantbutton2"/>
      <div id="content">
        <Main/>
      </div>
    </div>
  );
}

export default Layout;
