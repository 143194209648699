import React from 'react';

export default function Postgresql() {
  return (
    <div>
      <pre>
        <code>
          <p># Run Kubernetes Job from CronJob</p>
          <p>kubectl create job --from=cronjob/&lt;name of cronjob&gt; &lt;name of job&gt; </p>
        </code>
      </pre>
    </div>
  );
}
