import React, { Component } from "react";
import githubLogo from '../../github-mark.svg';
import { NavLink } from 'react-router-dom';

export default function TopBar({name}) {
  return (
    <div className="top-bar header">
      <div className="title">
        {name}
      </div>

      <div className="social-bar">
        <div>
          <a href="https://github.com/mutantbutton2">
            <img src={githubLogo} className="App-logo" alt="Github"/>
           </a>  
        </div>
      </div>

      <div className="nav-bar">
        <div className="link"><NavLink to="/">home</NavLink></div>
        <div className="divider">/</div>
        <div className="link"><NavLink to="/about-me">about me</NavLink></div>
        <div className="divider">/</div>
        <div className="link"><NavLink to="/blogs">blogs</NavLink></div>
        <div className="divider">/</div>
        <div className="link"><NavLink to="/bookmarks">bookmarks</NavLink></div>
      </div>
    </div>
  );
}
