import React from 'react';
import { useEffect } from 'react';
import { useState } from "react";
import './Section.css';

export default function Section({name, articles}) {

  const [open, setOpen] = useState(false);

  function handleToggle() {
    console.log(open)
    setOpen(!open);
  }

  function showArticles() {
    if (open) {
      if (articles.length == 0) {
        return <>This section contains no articles yet.</>
      } else {
        return <>
          {articles}
        </>
      }
    } else {
      return <></>
    }
  }

  function showIcon() {
    return open ? "▼" : "▶";
  }

  return (
    <div>
      <div className="section" onClick={handleToggle}>
        <a>{showIcon()} #{name}</a>
      </div>
      {showArticles()}
    </div>
  );
}