import React from 'react';
import { Route, Routes } from 'react-router-dom'
import Home  from './routes/Home';
import AboutMe from '../AboutMe/AboutMe';
import Blog from '../blog/Blog';
import Bookmark from '../bookmark/Bookmark';
import K8s from './routes/K8s';
import Postgresql from './routes/Postgresql';

import './Main.css';

function Main() {
  return (
    <Routes>
      
      <Route path="/" element={<Home/>}/>
      
      <Route path="/about-me" element={<AboutMe />}/>

      <Route path="/blogs" element={<Blog />}/>

      <Route path="/bookmarks" element={<Bookmark/>}/>

      <Route path="/bookmarks/k8s" element={<K8s />}/>
      <Route path="/bookmarks/postgres" element={<Postgresql />}/>
    </Routes>
  );
}

export default Main;
