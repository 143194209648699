import React from 'react';
import githubLogo from './github-mark.svg';
import './App.css';

import Layout from './components/layout/Layout';

function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
        <p>
          Joey Chan.
        </p>
        <a href="https://github.com/mutantbutton2">
          <img src={githubLogo} className="App-logo" alt="Github"/>
        </a>      
      </header> */}
      <Layout/>
    </div>
  );
}

export default App;
