import React from 'react';
import './Article.css';
import { NavLink } from 'react-router-dom'
import { Col } from 'react-bootstrap';

function Article({name, link}) {

  return (
    <Col className="article"><NavLink to={link}>◍ {name}</NavLink></Col>
  );
}

export default Article;
