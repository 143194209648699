import React from 'react';

function K8s() {
  return (
    <div>
      <div className='content'>
        <p>==========++++++++++==========++++K8s+++==========++++++++++==========</p>
        <pre>
          <code>
            <p># Run Kubernetes Job from CronJob</p>
            <p>kubectl create job --from=cronjob/&lt;name of cronjob&gt; &lt;name of job&gt; </p>
          </code>
        </pre>
      </div>
    </div>
  );
}

export default K8s;
