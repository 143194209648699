import React from 'react';

import profilePicture from '../../../profile.jpeg';

function Home() {
  return (
    <div>
      <div className="image-cropper">
        <img src={profilePicture} className="profile-picture" alt="Me"/>
      </div>
      <div className="header2" style={{'padding-top':'10px'}}>
        Hello.
      </div>
      <div>
        My name is Joey Chan.
      </div>
      <div>
        I like making things, mostly virtual or food.
      </div> 
    </div>
  );
}

export default Home;
